.app-grid
{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
        "recipes order-status   warehouse-stock"
        "recipes kitchen-orders warehouse-shopping-record";
    gap: 20px;
    width: 100%;
    height: 90vh;
    grid-template-columns: 1fr 1fr 1fr;
}